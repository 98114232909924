import React from 'react';
import PropTypes from 'prop-types';

import './Testimonial.scss';


const Testimonial = ({
  customerId,
  // date,
  text,
  discipline,
  topicTitle,
  grade,
}) => (
  <div className="tm-block">
    <div className="tm-block__header">
      <div className="tm-block__header__profile">
        <div className="tm-block__header__profile__avatar" />
        <div className="tm-block__header__profile__info">
          <div className="tm-block__header__profile__name">
            Customer (
            {customerId}
            )
          </div>
          {/* <div className="tm-block__header__profile__date"> */}
          {/* {date} */}
          {/* </div> */}
        </div>
      </div>
    </div>
    <div className="tm-block__body">
      <div className="tm-block__body__comment">
        <p className="tm-block__body__comment__paragraph">
          {text}
        </p>
      </div>
    </div>
    <div className="tm-block__footer">
      <div className="tm-block__footer__discipline">
        Discipline:
        {' '}
        <span>
          {discipline}
        </span>
      </div>
      <div className="tm-block__footer__topic-title">
        Topic title:
        {' '}
        <span>
          {topicTitle}
        </span>
      </div>
      <div className="tm-block__footer__grade">
        Grade:
        {' '}
        <span>
          {grade}
        </span>
      </div>
    </div>
  </div>
);

Testimonial.propTypes = {
  customerId: PropTypes.string,
  // date: PropTypes.string,
  text: PropTypes.string,
  discipline: PropTypes.string,
  topicTitle: PropTypes.string,
  grade: PropTypes.string,
};

Testimonial.defaultProps = {
  customerId: undefined,
  // date: undefined,
  text: undefined,
  discipline: undefined,
  topicTitle: undefined,
  grade: undefined,
};

export default Testimonial;
