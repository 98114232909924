// @todo enable next eslint rules
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Link } from 'gatsby';

import VerticalTextBlock from '../VerticalTextBlock';
import Testimonial from './Testimonial';
import tmData from './testimonials.json';

import './index.scss';


const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  cssEase: 'ease',
  easing: 'swing',
  slidesToScroll: 1,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        dots: true,
        arrows: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: true,
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
};

/**
 * @todo split `Testimonials` component
 * React best practices recommends to not combine business logic and UI.
 * Business logic is how component should fetch `Calc` widget.
 * UI is how component should looks like.
 * Testimonials representation should be consist of:
 * * data fetcher HOC
 * * 2 different Containers:
 *   * feed
 *   * carousel
 */
class Testimonials extends Component {
  /**
   * @todo remove useless `_getAllTestimonials` method binding
   *
   * @todo use class properties to define initial state
   * @example
   * class Testimonials extends Component {
   *   state = {
   *     showTestimonialsSize: 8,
   *     showBtn: true,
   *   };
   * }
   */
  constructor(props) {
    super(props);

    this.state = {
      showTestimonialsSize: 10,
      showBtn: true,
    };

    this._getAllTestimonials = this._getAllTestimonials.bind(this);
  }

  /**
   * @todo change method position in class
   * Non React Component methods should be placed between lifecycle methods and render methods.
   *
   * @todo rename method name
   * There are no private methods in JS.
   */
  _getAllTestimonials = (arr, e) => {
    e.preventDefault();

    /**
     * @todo use setState with function
     * @see https://reactjs.org/docs/state-and-lifecycle.html#using-state-correctly
     * @example
     * this.setState(({ showTestimonialsSize }) =>
     *   ({ showTestimonialsSize: showTestimonialsSize + 8 }));
     */
    this.setState({
      showTestimonialsSize: this.state.showTestimonialsSize + 10,
    });

    /**
     * Let's pretend I haven't seen this shit
     *
     * @todo use `setState` method
     * Component `state` is read only
     * @see https://reactjs.org/docs/components-and-props.html#props-are-read-only
     *
     * @todo use if..else statement
     * I don't get this:
     * @example
     * if (this.state.showTestimonialsSize > 30) {
     *   this.state.showBtn = false;
     * } else {
     *   true;
     * }
     */
    (this.state.showTestimonialsSize > arr.all.length - 10) ? this.state.showBtn = false : true;

    /**
     * @todo remove duplicate `preventDefault` method call
     */
    e.preventDefault();
  };


  /**
   * @todo move data fetching outside of Component class
   *
   * @todo use destructuring assignment
   */
  getTmData() {
    /**
     * @todo use const assignment for `nameOfPageArray`
     *
     * @todo remove useless `nameOfPageArray` prop validation
     * Property `nameOfPageArray` has default value `home`.
     */
    let nameOfPageArray = this.props.nameOfPageArray !== undefined ? this.props.nameOfPageArray : 'home';
    /**
     * @todo remove redundant variable `testimonialsData`
     */
    let testimonialsData = {};

    /**
     * @todo use if statement in combination with return instead of if...else if...else
     */
    if (typeof nameOfPageArray === 'object') {
      const allTms = {};
      /**
       * @todo remove redundant constant `newArr`
       */
      const newArr = [];

      /**
       * @todo add check on Array
       * `forEach` method is not defined in `object` type
       *
       * @todo use Array method `concat`
       * @example
       * allTms.customTmArray = nameOfPageArray.reduce((result, pageName) =>
       *   result.concat(tmData[pageName]), []);
       */
      nameOfPageArray.forEach((i) => {
        newArr.push(...tmData[i]);
      });

      /**
       * @todo use `return` statement
       * At this point all data is ready, no need to go further
       * @example
       * return {
       *   testimonialsData: allTms,
       *   nameOfPageArray: 'customTmArray',
       * };
       */
      allTms.customTmArray = newArr;
      nameOfPageArray = 'customTmArray';
      testimonialsData = allTms;
    } else if (nameOfPageArray === 'all') {
      const allTestimonials = [];
      /**
       * @todo use Object method `keys` instead of for...in
       * @example
       * const allTestimonials = Object.keys(tmData).reduce((result, pageName) =>
       *   result.concat(tmData[pageName]), []);
       */
      for (const i in tmData) {
        allTestimonials.push(...tmData[i]);
      }
      /**
       * @todo use `return` statement
       * At this point all data is ready, no need to go further
       * @example
       * return {
       *   testimonialsData: {
       *     all: allTestimonials,
       *   },
       *   nameOfPageArray,
       * };
       */
      testimonialsData.all = allTestimonials;
    } else {
      testimonialsData = tmData;
    }

    /**
     * @todo use as default return
     * @example
     * return {
     *   testimonialsData: tmData,
     *   nameOfPageArray,
     * };
     */
    return {
      testimonialsData,
      nameOfPageArray,
    };
  }

  /**
   * @todo add prop types
   *
   * @todo use template string vs string concatenation
   *
   * @todo use `classNames` utility for style class names combinations
   * @see https://github.com/JedWatson/classnames#classnames
   *
   * @todo split component render into conditional rendering of Components combination
   * @example
   * if (isOnlyContent)
   *  return (
   *    <TestimonialSlider>
   *      {children}
   *    </TestimonialSlider>
   *  );
   *
   * return (
   *  <TestimonialWrapper>
   *    <TestimonialSlider>
   *      {children}
   *    </TestimonialSlider>
   *  </TestimonialWrapper>
   * );
   *
   * @todo do not use array item index as Component key
   * @see https://reactjs.org/docs/lists-and-keys.html#keys
   *
   * @todo use destructuring to provide Testimonial data to Component
   * @example
   *  <Testimonial {...testimonial} />
   *
   * @todo simplify heavy sorting algorithm by data preparation
   *
   * @todo remove useless `_getAllTestimonials` method binding
   */
  render() {
    const { showTestimonialsSize, showBtn } = this.state;
    const { isOnlyContent } = this.props;
    const { testimonialsData, nameOfPageArray } = this.getTmData();

    return (
      <section
        className={`testimonials-section ${isOnlyContent ? 'testimonials-only-content' : ''}`}
      >
        {!isOnlyContent ? (
          <div className="testimonials-section__container container">
            <VerticalTextBlock
              sectionClassName="testimonials"
              text="Testimonials"
              dotsStyle="style-3"
            />

            <div className="testimonials-section__content">
              <h2 className="testimonials-section__heading">
                What our
                <br />
                {' '}
                customers say
              </h2>

              <div className="testimonials-section__slider">
                <Slider {...settings}>
                  {testimonialsData[nameOfPageArray]
                    .map((testimonial, key) => (
                      <div key={key}>
                        <Testimonial {...testimonial} />
                      </div>
                    ))
                  }
                </Slider>
              </div>

              <div>
                <Link
                  to="/testimonials.html"
                  className="testimonials-section__btn btn btn_normal btn_without-bg btn_blue btn_blue-color"
                >
                  View all testimonials
                </Link>
              </div>

            </div>
          </div>
        ) : (
          <div>
            {testimonialsData[nameOfPageArray]
              .sort((a, b) => (new Date(b.date) - new Date(a.date)))
              .slice(0, showTestimonialsSize)
              .map((testimonial, key) => (
                <Testimonial {...testimonial} key={key} />
              ))
            }
            <div className="testimonials-show-more-btn">
              {showBtn && (
                <Link
                  onClick={this._getAllTestimonials.bind(this, testimonialsData)}
                  to="/testimonials.html"
                  className="testimonials-section__btn btn btn_normal btn_without-bg btn_blue btn_blue-color"
                >
                  Show more testimonials
                </Link>
              )}
            </div>
          </div>
        )}
      </section>
    );
  }
}

/**
 * @todo change `isOnlyContent` property type
 * Property type should be boolean for flag indicator
 *
 * @todo change `nameOfPageArray` property type
 * Property type should be `oneOfType` for union types
 * @example
 * const TestimonialPageNamePropType = PropTypes.oneOf([
 *   'home',
 *   //...
 * ]);
 *
 * Testimonials.propTypes = {
 *   // ...
 *   nameOfPageArray: PropTypes.oneOfType([
 *     TestimonialPageNamePropType,
 *     PropTypes.arrayOf(TestimonialPageNamePropType)
 *   ]),
 * };
 */
Testimonials.propTypes = {
  isOnlyContent: PropTypes.bool,
  nameOfPageArray: PropTypes.string,
};

Testimonials.defaultProps = {
  isOnlyContent: false,
  nameOfPageArray: 'home',
};

export default Testimonials;
